import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import MainComp from "../components/Layouts/MainComp";
import FooterComp from "../components/Layouts/FooterComp";
import SidebarComp from "../components/Layouts/SidebarComp";
import AuthContextProvider from "../contexts/AuthContext";
import HeaderComp from "../components/Layouts/HeaderComp";

export default function Home() {
  const [sidebar, setSidebar] = useState(false);
  const toggle = () => {
    setSidebar(!sidebar);
  };

  return (
    <>
      <Container fluid className="layout">
        <Router>
          <AuthContextProvider>
            <HeaderComp toggle={toggle} />
            <Container fluid className="main-layout">
              <SidebarComp sidebar={sidebar} setSidebar={setSidebar} />
              <Container
                fluid
                className={
                  sidebar ? "main-container open" : "main-container closed"
                }
              >
                <MainComp />
                {/* <FooterComp /> */}
              </Container>
            </Container>
            <ToastContainer autoClose={3500} draggable newestOnTop />
          </AuthContextProvider>
        </Router>
      </Container>
    </>
  );
}
