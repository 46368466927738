import React, { useState } from "react";
import LoginLocalAuth from "./components/LoginLocalAuth";
import "./Login.css";
import Home from "./components/Home";

function AppLocalAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <>
      {!isAuthenticated ? (
        <LoginLocalAuth setIsAuthenticated={setIsAuthenticated} />
      ) : (
        <Home setIsAuthenticated={setIsAuthenticated} />
      )}
    </>
  );
}

export default AppLocalAuth;
