import { useMsal } from "@azure/msal-react";
import { Col, Container, Button, Row } from "react-bootstrap";
import { loginRequest } from "../authConfig";
import godrejlogo from "../images/godrej-logo-login-header.svg"

export default function Login({ validUser }) {
  const { instance } = useMsal();
  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  return (
    <Container className="login-container">
      <Row className="login-width">
        <Col xs={12}>
          <div class="d-flex justify-content-between align-items-center my-3 logoHeader">
            <img src={godrejlogo} />
            <span class="headerLink">Godrej Enterprises Group</span>
          </div>
        </Col>
        <Col md={8}>
          {/* <Image src={LoginImage} alt="Login_Image" /> */}
          <div className="login-container-left"></div>
        </Col>
        <Col
          md={4}
          className="d-flex flex-column justify-content-center min-vh-100 login-container-right"
        >
          <h2 className="app-name">
            Online Design
            <br />
            Release System
            <br />
          </h2>
          <Button
            variant="primary" className="login-btn"
            onClick={() => handleLogin(instance)}
          >
            Login
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
