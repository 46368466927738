import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
import { register as registerServiceWorker } from "./serviceWorkerRegistration";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import AppLocalAuth from "./AppLocalAuth";

const msalInstance = new PublicClientApplication(msalConfig);
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
    {/* commented code AppLocalAuth is local auth */}
    {/* <AppLocalAuth /> */}
  </MsalProvider>,
  document.getElementById("root")
);

registerServiceWorker();
