import { Container, Navbar, Button, Dropdown } from "react-bootstrap";
import { BsPersonCircle } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import Toggler from "../../images/Toggler.svg";
import profile from "../../images/profile.svg";

const HeaderComp = ({ toggle }) => {
  const { userName } = useContext(AuthContext);
  const { instance } = useMsal();
  const navigate = useNavigate();

  const logout = (instance) => {
    sessionStorage.removeItem("Token");
    sessionStorage.removeItem("UserData");
    navigate("/");
    //comment below 17 to 19 logoutRedirect lines for local auth
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };
  return (
    <Navbar className="header" sticky="top">
      <Container>
        <div className="toggle-icon-wrapper">
          <img
            src={Toggler}
            alt="Toggler"
            onClick={toggle}
            className="head-toggle" />
          {/* <Button className="headToggler" onClick={toggle}>
            <FaBars size="15" />
          </Button> */}
        </div>
        <Navbar.Brand>
          {/* <img src={logo} alt="Godrej" /> */}
          <Navbar.Text>Online Design Release System</Navbar.Text>
        </Navbar.Brand>
        <div className="welcome-text">
          <span className="account-user-name">Welcome, {userName}</span>
          <Dropdown autoClose="outside">
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu align={"end"}>
              <Dropdown.Item
                eventKey="4"
                className="text-danger"
                onClick={() => logout(instance)}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
    </Navbar>
  );
};

export const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <img src={profile}
    alt="profile"
    className="avtar"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  />
));

export default HeaderComp;
