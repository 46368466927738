export function setError(input, message) {
  const formControl = input.parentElement;
  const small = formControl.querySelector("small");
  formControl.className = "control error";
  small.innerText = message;
}

export function setSuccess(input) {
  const formControl = input.parentElement;
  formControl.className = "control success";
}

export function innerJoin(arr1, arr2, primary, foreign) {
  let callback = (x, y) => x[primary] === y[foreign] && { ...x, ...y };
  return arr1.reduce(
    (arr3, x) =>
      arr2.reduce((arr3, y) => arr3.concat(callback(x, y) || []), arr3),
    []
  );
}

export function leftJoin(arr1, arr2, primary, foreign) {
  let left_join = arr1.map((x) => ({
    ...arr2.find((y) => y[foreign] === x[primary]),
    ...x,
  }));
  return left_join;
}

export function authHeader() {
  const user = sessionStorage.getItem("UserData");
  const token = sessionStorage.getItem("Token");

  if (user && token) {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  } else {
    return {};
  }
}

export function compareGreaterOrEqualDates(date1, date2) {
  let isGreater = true;
  if (date1 !== "" && date2 !== "") {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    if (d1.getTime() > d2.getTime()) {
      isGreater = false;
    }
  } else {
    isGreater = false;
  }
  return isGreater;
}

export function compareLesserOrEqualDates(date1, date2) {
  let isGreater = true;
  if (date1 !== "" && date2 !== "") {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    if (d1.getTime() < d2.getTime()) {
      isGreater = false;
    }
  } else {
    isGreater = false;
  }
  return isGreater;
}

export function formatDateInDDMMYYYY(dateStr) {
  if (dateStr && dateStr !== "") {
    let newDate = "";
    let separator = "/";
    let str = "";
    let time = "";
    if (dateStr?.indexOf(" ") !== -1) {
      [str] = dateStr?.split(" ");
      time = dateStr?.split(" ")[1];
    } else {
      str = dateStr;
    }
    if (str !== "") {
      let [year, month, day] = str.split("-");
      newDate = `${day}${separator}${month}${separator}${year} ${time}`;
    }
    return newDate;
  } else {
    return "";
  }
}

export function formatDateInDDMMYYYY_Date(dateStr) {
  if (dateStr !== "") {
    let newDate = "";
    let separator = "/";
    let str = "";
    if (dateStr?.indexOf(" ") !== -1) {
      [str] = dateStr?.split(" ");
    } else {
      str = dateStr;
    }
    if (str !== "") {
      let [year, month, day] = str.split("-");
      newDate = `${day}${separator}${month}${separator}${year}`;
    }
    return newDate;
  } else {
    return "";
  }
}

export function getCurrentDate(separator = "/") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  date = date < 10 ? "0" + date : date;
  month = month < 10 ? "0" + month : month;

  return `${date}${separator}${month}${separator}${year}`;
}

export function getCurrentDateTime(separator = "/") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  let seconds = newDate.getSeconds();
  date = date < 10 ? "0" + date : date;
  month = month < 10 ? "0" + month : month;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return `${year}${separator}${month}${separator}${date} ${hours}:${minutes}:${seconds}`;
}

export function getCurrentMonthYear() {
  let newDate = new Date();
  let month = newDate.toLocaleString("default", { month: "short" });
  let year = newDate.getFullYear();

  return `${month}-${year}`;
}

export function getAllMonthsTillCurrentMonth(startDate, endDate) {
  let currentDate = new Date(endDate);
  let stDate = new Date(startDate);
  let allMonths = [];
  let month = "";
  let year = "";

  while (stDate <= currentDate) {
    month = stDate.toLocaleString("default", { month: "short" });
    year = stDate.getFullYear();
    allMonths.push(month + "-" + year);
    stDate = new Date(stDate.setMonth(stDate.getMonth() + 1));
  }
  return allMonths;
}

export function dateDiffInDays(date1, date2) {
  if (date1 !== "" && date2 !== "") {
    let dt1 = new Date(date1);
    let dt2 = new Date(date2);
    dt1.setUTCHours(0, 0, 0, 0);
    dt2.setUTCHours(0, 0, 0, 0);
    return (dt2.getTime() - dt1.getTime()) / (1000 * 3600 * 24);
  } else {
    return "";
  }
}

export function sortByPropertyInAscending(property) {
  return function (a, b) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;
    return 0;
  };
}

export function sortByPropertyInDescending(property) {
  return function (a, b) {
    if (a[property] < b[property]) return 1;
    else if (a[property] > b[property]) return -1;
    return 0;
  };
}

export function getDistinctMailId(str) {
  let allElements = [...new Set(str.split(";"))];
  let distinctId = "";
  if (allElements.length > 0) {
    distinctId = allElements.join(";");
  }
  return distinctId;
}

export function groupBy(array, key) {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    let val = currentValue[key];
    result[val] = result[val] || [];
    result[val].push(currentValue);
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, []); // empty object is the initial value for result object
}

// Group by color as key to the person array

export function checkObjectProperty(data, property) {
  if (data.hasOwnProperty(property)) {
    return data[property];
  } else {
    return "";
  }
}

export const convertToDate = (dateStr) => {
  let dateParts = dateStr.split("/");
  let date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return date;
};

export const isNumber = (value) => {
  return !isNaN(parseFloat(value)) && !isNaN(value - 0);
};

export function formatDateDisplay(dateStr) {
  if (dateStr !== "") {
    let newDate = "";
    let separator = "/";
    let str = "";
    if (dateStr.indexOf(" ") !== -1) {
      [str] = dateStr.split(" ");
    } else {
      str = dateStr;
    }
    if (str !== "") {
      let [year, month, day] = str.split("-");
      newDate = `${day}${separator}${month}${separator}${year}`;
    }
    return newDate;
  } else {
    return "";
  }
}

export const dateSorter = (a, b) => {
  const getDate = (date) => {
    let day = date.split("-");
    day[0] =
      {
        jan: 1,
        feb: 2,
        mar: 3,
        apr: 4,
        may: 5,
        jun: 6,
        jul: 7,
        aug: 8,
        sep: 9,
        oct: 10,
        nov: 11,
        dec: 12,
      }[day[0].substring(0, 3).toLowerCase()] || 0;
    return day;
  };
  const aDate = getDate(a);
  const bDate = getDate(b);
  return aDate[1] - bDate[1] || aDate[0] - bDate[0];
};

const getMonth = (dateObj) => {
  let currentMonth =
    dateObj.getMonth() + 1 <= 9
      ? "0" + (dateObj.getMonth() + 1)
      : dateObj.getMonth() + 1;

  return currentMonth;
};

const getDate = (dateObj) => {
  let currDate =
    dateObj.getDate() <= 9 ? "0" + dateObj.getDate() : dateObj.getDate();
  return currDate;
};

export function convertDateTimetoDateFormat(dateTime) {
  if (dateTime && dateTime != "" && dateTime != "null") {
    let date_obj = new Date(dateTime);
    let month = getMonth(date_obj);
    let date = getDate(date_obj);
    let currentDate = `${date_obj.getFullYear()}-${month}-${date}`;
    return currentDate;
  } else {
    return "";
  }
};

export function getMonthYear(date) {
  if (date !== "") {
    let day = date.split("-");
    day[1] =
      {
        '01': 'Jan',
        '02': 'Feb',
        '03': 'Mar',
        '04': 'Apr',
        '05': 'May',
        '06': 'Jun',
        '07': 'Jul',
        '08': 'Aug',
        '09': 'Sep',
        '10': 'Oct',
        '11': 'Nov',
        '12': 'Dec',
      }[day[1]] || 0;
    return day[1] + "-" + day[0];
  }
  else {
    return "";
  }
}

export function getDateFromMonthYear(date) {
  if (date !== "") {
    let day = date.split("-");
    day[0] =
      {
        jan: 1,
        feb: 2,
        mar: 3,
        apr: 4,
        may: 5,
        jun: 6,
        jul: 7,
        aug: 8,
        sep: 9,
        oct: 10,
        nov: 11,
        dec: 12,
      }[day[0].substring(0, 3).toLowerCase()] || 0;
    return day[1] + "-" + day[0];
  }
  else {
    return "";
  }
}