import { createContext, Component } from "react";

export const AuthContext = createContext();

class AuthContextProvider extends Component {
  constructor() {
    super();

    const user = JSON.parse(sessionStorage.getItem("UserData"));
    this.state = {
      loggedUserId: user.UserId,
      userName: user.UserName,
      role: user.Role,
      dashboardAccess: user.DashboardAccess,
      userDept: user.UserDepartment
    };
  }

  render() {
    return (
      <AuthContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContextProvider;
