import React, { useState, useEffect } from "react";
import Login from "./components/Login";
import "./Login.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { authenticate } from "./services/user-master-service";
import ErrorPage from "./components/ErrorPage";
import Home from "./components/Home";
import withClearCache from "./ClearCache";
const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [validUser, setValidUser] = useState();
  const [idToken, setIdToken] = useState(null);
  const [sessionExp, setSessionExp] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData();
    }
    if (idToken && idToken !== "") {
      getUserData();
    }
  }, [isAuthenticated, idToken]); // eslint-disable-line react-hooks/exhaustive-deps
  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setIdToken(response.idToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setIdToken(response.idToken);
        });
      });
  }
  async function getUserData() {
    let emailId = accounts[0].username;
    let email = emailId.replace("@godrej.com", "");
    let result = await authenticate(email, idToken).catch((err) =>
      console.log("Error in fetching details", err)
    );
    if (result && result.UserData && result.UserData !== "") {
      sessionStorage.setItem(
        "UserData",
        result.UserData ? JSON.stringify(result.UserData) : ""
      );

      sessionStorage.setItem("Token", result.Token);
      setValidUser(true);
    } else if (result && result.message === "TokenExpiredError") {
      setValidUser(false);
      setSessionExp(true);
      sessionStorage.removeItem("UserData");
      sessionStorage.removeItem("Token");
    } else {
      setValidUser(false);
      sessionStorage.removeItem("UserData");
      sessionStorage.removeItem("Token");
    }
  }

  return (
    <>
      <AuthenticatedTemplate>
        {validUser === true && sessionExp === false && <Home />}
        {(validUser === false || sessionExp === true) && (
          <ErrorPage validUser={validUser} sessionExp={sessionExp} />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
