import { Container, Row, Col } from "react-bootstrap";

export default function ErrorPage({ validUser, sessionExp }) {
  return (
    <Container className="error-box">
      <Row>
        <Col>
          {!validUser && (
            <div className="error-content">
              {" "}
              <h4>Oh! User does not exist/not active in application</h4>
              <p>Kindly Contact Admin.</p>
            </div>
          )}
          {sessionExp && (
            <div className="error-content">
              {" "}
              <h4>Session Timeout!!!</h4>
              <p>Kindly close the session and login again.</p>
            </div>
          )}{" "}
        </Col>
      </Row>
    </Container>
  );
}
