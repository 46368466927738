import React, { Suspense, lazy, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";

const Dashboard = lazy(() => import("../Dashboard"));
const DeptMaster = lazy(() => import("../Masters/DeptMaster"));
const DesignPlanMaster = lazy(() => import("../Masters/DesignPlanMaster"));
const UserMaster = lazy(() => import("../Masters/UserMaster"));
const DocMaster = lazy(() => import("../Masters/DocMaster"));
const ProjectUserMatrix = lazy(() => import("../Matrix/ProjectUserMatrix"));
const ProjectMaster = lazy(() => import("../Masters/ProjectMaster"));
const WorkFlowMatrix = lazy(() => import("../Matrix/WorkFlowMatrix"));
const SearchAndExcel = lazy(() => import("../DesignIssue/SearchAndExcel"));
const DesignIssueList = lazy(() => import("../DesignIssue/DesignIssueList"));
const SearchAndDashboard = lazy(() => import("../DesignIssue/SearchAndDashboard"));
const Help = lazy(() => import("../Masters/Help"));
const FormatMaster = lazy(() => import("../Masters/FormatMaster"));

const MainComp = () => {
  const context = useContext(AuthContext);
  return (
    <Suspense
      fallback={
        <div className="lazy-spinner">
          <Spinner animation="border" className="spinner-color" />
        </div>
      }
    >
      <main className="layout-content">
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/departments" element={<DeptMaster />} />
          <Route exact path="/users" element={<UserMaster />} />
          <Route exact path="/projects" element={<ProjectMaster />} />
          <Route exact path="/documents" element={<DocMaster />} />
          <Route exact path="/subassembly" element={<DesignPlanMaster />} />
          <Route exact path="/projectmatrix" element={<ProjectUserMatrix />} />
          <Route exact path="/workflow" element={<WorkFlowMatrix />} />
          <Route exact path="/designissue" element={<DesignIssueList />} />
          <Route exact path="/search" element={<SearchAndExcel />} />
          {context.dashboardAccess === "Yes" && (
            <Route exact path="/dashboard" element={<SearchAndDashboard />} />
          )}
          <Route exact path="/help" element={<Help />} />
          <Route exact path="/format" element={<FormatMaster />} />
        </Routes>
      </main>
    </Suspense>
  );
};

export default MainComp;
