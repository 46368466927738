import React, { useState, useEffect, useContext } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Col, Collapse, Image, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import {
  BsHouseDoor,
  BsDiagram3,
  BsPerson,
  BsLayers,
  BsFileEarmarkText,
  BsJournalText,
  BsListUl,
  BsBoundingBox,
  BsSearch,
  BsColumnsGap, BsClipboard2Check,
} from "react-icons/bs";
import { RiUserSettingsLine, RiDatabase2Line } from "react-icons/ri";
import { BiHelpCircle } from "react-icons/bi";
import godrejlogoMedium from "../../images/godrej-logo-medium.svg"
import format from "../../images/format_Master.svg"

const SidebarComp = ({ sidebar, setSidebar }) => {
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);
  const [navText, setNavText] = useState("");
  const context = useContext(AuthContext);

  useEffect(() => {
    if (sidebar) {
      setNavText("navlink-text");
    } else {
      setNavText("navlink-text hide");
    }
  }, [sidebar]);

  const showSidebar = () => {
    setSidebar(!sidebar);
    setMenu1(false);
    setMenu2(false);
  };

  return (
    <>
      <Navbar.Collapse
        as={"aside"}
        expand="lg"
        id="SideBar"
        className={
          sidebar ? "open-sidebar layout-sider" : "closed-sidebar layout-sider"
        }
      >
        <div
          className={
            sidebar
              ? "open-sidebar sider-children"
              : "closed-sidebar sider-children"
          }
        >
          <BiChevronRight className="navToggler" onClick={showSidebar} />
          <Nav as={"ul"} bsPrefix="navigation-list">
            <Nav.Item as={"li"} bsPrefix="navigation-list-item">
              <Nav.Link
                as={Link}
                bsPrefix="navigation-link"
                to="/"
                onClick={() => {
                  setSidebar(false);
                }}
              >
                <Row>
                  <Col xs="2">
                    <BsHouseDoor size={"24px"} title="Home" />
                  </Col>
                  <Col xs="10" className={navText}>
                    Home
                  </Col>
                </Row>
                {/* <span className="tooltip_menu">Home</span> */}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item
              as={"li"}
              bsPrefix="navigation-list-item"
              onClick={() => setMenu1(!menu1)}
            >
              <Nav.Link bsPrefix="navigation-link">
                <Row>
                  <Col xs="2">
                    <RiDatabase2Line size={"24px"} title="Master Data" />
                  </Col>
                  <Col xs="10" className={navText}>
                    Master Data
                  </Col>
                </Row>
                {/* <span className="tooltip_menu">Master Data</span> */}
              </Nav.Link>
            </Nav.Item>
            <Collapse in={menu1}>
              <div className="navigation-link-subMenu">
                <Nav.Item as={"li"} bsPrefix="navigation-list-item">
                  <Nav.Link
                    as={Link}
                    bsPrefix="navigation-link"
                    to="/departments"
                    onClick={() => {
                      setSidebar(false);
                    }}
                  >
                    <Row>
                      <Col xs="2">
                        <BsDiagram3 size={"24px"} title="Department" />
                      </Col>
                      <Col xs="10" className={navText}>
                        Department
                      </Col>
                    </Row>
                    {/* <span className="tooltip_menu">Department</span> */}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as={"li"} bsPrefix="navigation-list-item">
                  <Nav.Link
                    as={Link}
                    bsPrefix="navigation-link"
                    to="/users"
                    onClick={() => {
                      setSidebar(false);
                    }}
                  >
                    <Row>
                      <Col xs="2">
                        <BsPerson size={"24px"} title="User" />
                      </Col>
                      <Col xs="10" className={navText}>
                        User
                      </Col>
                    </Row>
                    {/* <span className="tooltip_menu">User</span> */}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as={"li"} bsPrefix="navigation-list-item">
                  <Nav.Link
                    as={Link}
                    bsPrefix="navigation-link"
                    to="/projects"
                    onClick={() => {
                      setSidebar(false);
                    }}
                  >
                    <Row>
                      <Col xs="2">
                        <BsLayers size={"24px"} title="Project" />
                      </Col>
                      <Col xs="10" className={navText}>
                        Project
                      </Col>
                    </Row>
                    {/* <span className="tooltip_menu">Project</span> */}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as={"li"} bsPrefix="navigation-list-item">
                  <Nav.Link
                    as={Link}
                    bsPrefix="navigation-link"
                    to="/documents"
                    onClick={() => {
                      setSidebar(false);
                    }}
                  >
                    <Row>
                      <Col xs="2">
                        <BsFileEarmarkText size={"24px"} title="Document Type" />
                      </Col>
                      <Col xs="10" className={navText}>
                        Document Type
                      </Col>
                    </Row>
                    {/* <span className="tooltip_menu">Document Type</span> */}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as={"li"} bsPrefix="navigation-list-item">
                  <Nav.Link
                    as={Link}
                    bsPrefix="navigation-link"
                    to="/subassembly"
                    onClick={() => {
                      setSidebar(false);
                    }}
                  >
                    <Row>
                      <Col xs="2">
                        <BsJournalText size={"22px"} title="Design Plan" />
                      </Col>
                      <Col xs="10" className={navText}>
                        Design Plan
                      </Col>
                    </Row>
                    {/* <span className="tooltip_menu">Design Plan</span> */}
                  </Nav.Link>
                </Nav.Item>
                {context.role === "Super Admin" &&
                  <Nav.Item as={"li"} bsPrefix="navigation-list-item">
                    <Nav.Link
                      as={Link}
                      bsPrefix="navigation-link"
                      to="/format"
                      onClick={() => {
                        setSidebar(false);
                      }}
                    >
                      <Row className="action-icon">
                        <Col xs="2">
                          <Image width={"20px"} src={format} title="Format Master"></Image>
                        </Col>
                        <Col xs="10" className={navText}>
                          Format Master
                        </Col>
                      </Row>
                    </Nav.Link>
                  </Nav.Item>
                }
              </div>
            </Collapse>
            <Nav.Item
              as={"li"}
              bsPrefix="navigation-list-item"
              onClick={() => setMenu2(!menu2)}
            >
              <Nav.Link bsPrefix="navigation-link">
                <Row>
                  <Col xs="2">
                    <BsListUl size={"22px"} title="Matrix Data" />
                  </Col>
                  <Col xs="10" className={navText}>
                    Matrix Data
                  </Col>
                </Row>
                {/* <span className="tooltip_menu">Matrix Data</span> */}
              </Nav.Link>
            </Nav.Item>
            <Collapse in={menu2}>
              <div className="navigation-link-subMenu">
                <Nav.Item as={"li"} bsPrefix="navigation-list-item">
                  <Nav.Link
                    as={Link}
                    bsPrefix="navigation-link"
                    to="/projectmatrix"
                    onClick={() => {
                      setSidebar(false);
                    }}
                  >
                    <Row>
                      <Col xs="2">
                        <RiUserSettingsLine size={"24px"} title="Project User Matrix" />
                      </Col>
                      <Col xs="10" className={navText}>
                        Project User Matrix
                      </Col>
                    </Row>
                    {/* <span className="tooltip_menu">Project User Matrix</span> */}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as={"li"} bsPrefix="navigation-list-item">
                  <Nav.Link
                    as={Link}
                    bsPrefix="navigation-link"
                    to="/workflow"
                    onClick={() => {
                      setSidebar(false);
                    }}
                  >
                    <Row>
                      <Col xs="2">
                        <BsBoundingBox size={"20px"} title="WorkFlow Matrix" />
                      </Col>
                      <Col xs="10" className={navText}>
                        WorkFlow Matrix
                      </Col>
                    </Row>
                    {/* <span className="tooltip_menu">WorkFlow Matrix</span> */}
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Collapse>
            <Nav.Item as={"li"} bsPrefix="navigation-list-item">
              <Nav.Link
                as={Link}
                bsPrefix="navigation-link"
                to="/designissue"
                onClick={() => {
                  setSidebar(false);
                }}
              >
                <Row>
                  <Col xs="2">
                    <BsClipboard2Check size={"20px"} title="Design Release" />
                  </Col>
                  <Col xs="10" className={navText}>
                    Design Release
                  </Col>
                </Row>
                {/* <span className="tooltip_menu">Design Release</span> */}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as={"li"} bsPrefix="navigation-list-item">
              <Nav.Link
                as={Link}
                bsPrefix="navigation-link"
                to="/search"
                onClick={() => {
                  setSidebar(false);
                }}
              >
                <Row>
                  <Col xs="2">
                    <BsSearch size={"20px"} title="Search &amp; Excel" />
                  </Col>
                  <Col xs="10" className={navText}>
                    Search &amp; Excel
                  </Col>
                </Row>
                {/* <span className="tooltip_menu">Search &amp; Excel</span> */}
              </Nav.Link>
            </Nav.Item>
            {context.dashboardAccess === "Yes" && (
              <Nav.Item as={"li"} bsPrefix="navigation-list-item">
                <Nav.Link
                  as={Link}
                  bsPrefix="navigation-link"
                  to="/dashboard"
                  onClick={() => {
                    setSidebar(false);
                  }}
                >
                  <Row>
                    <Col xs="2">
                      <BsColumnsGap size={"20px"} title="Dashboard" />
                    </Col>
                    <Col xs="10" className={navText}>
                      Dashboard
                    </Col>
                  </Row>
                  {/* <span className="tooltip_menu">Dashboard</span> */}
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item as={"li"} bsPrefix="navigation-list-item">
              <Nav.Link
                as={Link}
                bsPrefix="navigation-link"
                to="/help"
                onClick={() => {
                  setSidebar(false);
                }}
              >
                <Row>
                  <Col xs="2">
                    <BiHelpCircle size={"20px"} title="Help" />
                  </Col>
                  <Col xs="10" className={navText}>
                    Help
                  </Col>
                </Row>
                {/* <span className="tooltip_menu">Help</span> */}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="sidebarLogo">
            <img src={godrejlogoMedium} alt="Godrej" className="sidebar-logo" />
          </div>
        </div>
      </Navbar.Collapse>
    </>
  );
};

export default SidebarComp;
